@import "../../ui/media.scss";

.quiz-complete {
  .question-complete {
    margin: 50px 0;
  }

  .quiz-complete-top {
    display: flex;
  }

  .quiz-complete-top-text {
    flex: 0 0 calc(100% - 60px);
    padding: 0 0 0 20px;

    @include landscape {
      flex: 0 0 calc(100% - 100px);
      padding: 0 0 0 50px;
    }

    strong + p {
      line-height: 1.5;
      margin: 0;

      @include landscape {
        margin: 0 0 6px 0;
      }
    }
  }

  .quiz-complete-top-indicator {
    flex: 0 0 60px;
    padding: 0;

    @include landscape {
      flex: 0 0 100px;
    }
  }
}
