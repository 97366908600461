.level-tag {
  // position: absolute;
  width: 40px;
  // padding: 3px 10px;
  z-index: 2;
  // background-color: black;

  svg {
    display: block;
    margin: 0 0 4px 0;
    // fill: white;
  }

  .level-tag-text {
    text-align: center;
    text-transform: capitalize;
    font-size: 1.2rem;
    // line-height: 1;
    // font-weight: 700;
    // font-style: italic;
  }
}
