.copy-field {
  position: relative;
  z-index: 10;

  .copy-field-text {
    background-color: var(--color-grey-background);
    border: 1px solid var(--color-grey-background-border);
    height: 38px;
    line-height: 16px;
    border-radius: 5px;
    padding: 10px 12px;
    position: relative;
    display: block;
    width: 100%;
    text-align: left;

    .icon-copy {
      fill: var(--color-text);
      width: 16px;
      height: 16px;
    }

    &:hover,
    &:focus,
    &:active {
      .copy-field-icon {
        background-color: var(--color-grey-background-border);
        background-color: var(--color-disabled);
      }
    }

    .copy-field-icon {
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      right: 4px;
      top: 3px;
      padding: 7px;
      background-color: transparent;
      transition: background-color 300ms ease;
    }
  }

  .copy-field-text {
    cursor: pointer;
  }

  &.has-error {
    .copy-field-error {
      opacity: 1;
    }
  }

  &.has-loaded {
    .copy-field-success {
      opacity: 1;
    }
  }

  .copy-field-error,
  .copy-field-success {
    position: absolute;
    top: -36px;
    left: 0;
    padding: 0 10px;
    border-radius: 5px;
    pointer-events: none;
    transition: opacity 300ms ease;
    opacity: 0;
  }

  .copy-field-success {
    background-color: var(--color-success);
    color: var(--color-white);
  }

  .copy-field-error {
    background-color: var(--color-error);
    color: var(--color-white);
  }
}
