@import "../../ui/media.scss";

.map-point {
  display: none;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;

  @include tablet {
    width: 10px;
    height: 10px;
  }

  .map-point-outer {
    fill: var(--color-white);
    stroke: var(--color-black);
  }

  .map-point-inner {
    fill: var(--color-black);

    stroke: none;
  }
}

.point.with-link {
  .map-point-outer {
    stroke: var(--color-info-button);
  }

  .map-point-inner {
    fill: var(--color-info-button);
  }
}

.point {
  .map-point {
    display: block;
  }

  &.on-right,
  &.on-left {
    .map-point {
      top: 7px;

      @include tablet {
        top: 9px;
      }
    }
  }

  &.on-left {
    .map-point {
      right: -14px;

      @include tablet {
        right: -18px;
      }
    }
  }

  &.on-right {
    .map-point {
      left: -14px;

      @include tablet {
        left: -18px;
      }
    }
  }

  &.on-top,
  &.on-bottom {
    .map-point {
      left: 50%;
      margin: 0 0 0 -4px;

      @include tablet {
        margin: 0 0 0 -5px;
      }
    }
  }

  &.on-top {
    .map-point {
      bottom: -13px;

      @include tablet {
        bottom: -17px;
      }
    }
  }

  &.on-bottom {
    .map-point {
      top: -13px;

      @include tablet {
        top: -17px;
      }
    }
  }
}

a.map-tooltip.with-link {
  &:hover,
  &:active,
  &:focus {
    color: var(--color-white);
    background-color: var(--color-info-button);

    .map-tooltip-arrow {
      background-color: var(--color-info-button);
    }
  }
}

.supernova-in-the-east-map > .is-active,
.hundred-years-war-map.is-active {
  .map-tooltip {
    opacity: 1;
    pointer-events: all;
  }
}

.supernova-in-the-east-map > .is-animating {
  .map-tooltip {
    opacity: 0;
  }
}

.map-tooltip {
  position: absolute;
  z-index: 50;
  font-size: 1.4rem;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 2px;
  height: 22px;
  line-height: 22px;
  display: flex;
  background-color: var(--color-black);
  color: var(--color-white);
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.3);
  padding: 0 6px;
  transition: opacity 300ms ease;
  opacity: 0;
  pointer-events: none;

  &.is-active {
    opacity: 1;
    pointer-events: all;
  }

  @include tablet {
    padding: 0 8px;
    border-radius: 4px;
    height: 28px;
    line-height: 28px;
    font-size: 1.8rem;
  }

  .map-tooltip-arrow {
    display: block;
    background-color: var(--color-black);
    height: 6px;
    width: 6px;
    position: absolute;
    transform: translateX(-3px) rotate(45deg);
    z-index: 1;

    @include tablet {
      height: 8px;
      width: 8px;
      transform: translateX(-4px) rotate(45deg);
    }
  }

  .map-tooltip-info {
    position: relative;
    z-index: 2;
  }

  .map-tooltip-info-icon {
    background-color: var(--color-info-button);
    margin: 0 0 0 5px;
    height: 22px;
    line-height: 22px;
    padding: 5px 3px 0 4px;
    font-weight: normal;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;

    & > svg {
      width: 12px;
      height: 12px;
    }

    @include tablet {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      height: 28px;
      line-height: 28px;
      padding: 5px 4px 0 6px;
      margin: 0 0 0 8px;

      & > svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  &.with-link {
    color: var(--color-white);
    background-color: var(--color-link);
    text-decoration: none;
    padding: 0 0 0 6px;
    transition: background-color 300ms ease, opacity 300ms ease;

    @include tablet {
      padding: 0 0 0 8px;
    }

    .map-tooltip-arrow {
      background-color: var(--color-link);
      transition: background-color 200ms ease;
    }

    &.on-left {
      .map-tooltip-arrow {
        background-color: var(--color-info-button);
      }
    }
  }

  &.on-bottom {
    transform: translate3d(-50%, 3px, 0);

    @include tablet {
      transform: translate3d(-50%, 4px, 0);
    }

    &.point {
      transform: translate3d(-50%, 9px, 0);

      @include tablet {
        transform: translate3d(-50%, 12px, 0);
      }
    }

    .map-tooltip-arrow {
      top: -3px;
      left: 50%;

      @include tablet {
        top: -4px;
      }
    }
  }

  &.on-right {
    transform: translate3d(3px, -11px, 0);

    @include tablet {
      transform: translate3d(4px, -14px, 0);
    }

    &.point {
      transform: translate3d(11px, -11px, 0);

      @include tablet {
        transform: translate3d(13px, -14px, 0);
      }
    }

    .map-tooltip-arrow {
      left: 0;
      top: 8px;

      @include tablet {
        top: 10px;
      }
    }
  }

  &.on-top {
    transform: translate3d(-50%, -23px, 0);

    @include tablet {
      transform: translate3d(-50%, -32px, 0);
    }

    &.point {
      transform: translate3d(-50%, -29px, 0);

      @include tablet {
        transform: translate3d(-50%, -39px, 0);
      }
    }

    .map-tooltip-arrow {
      left: 50%;
      top: 19px;

      @include tablet {
        top: 24px;
      }
    }
  }

  &.on-left {
    transform: translate3d(calc(-100% - 3px), -11px, 0);

    @include tablet {
      transform: translate3d(calc(-100% - 4px), -14px, 0);
    }

    &.point {
      transform: translate3d(calc(-100% - 11px), -11px, 0);

      @include tablet {
        transform: translate3d(calc(-100% - 13px), -14px, 0);
      }
    }

    .map-tooltip-arrow {
      left: 100%;
      top: 8px;

      @include tablet {
        top: 10px;
      }
    }
  }
}
