@import "../../ui/media.scss";

.hundred-years-war {
  padding: 0 0 80px 0;
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  @include tablet {
    display: flex;
    justify-content: flex-end;
  }

  .info-wrapper {
    @include tablet {
      max-width: $desktop;
      padding: 20px 20px 40px 0;
      order: 1;
      width: 50%;
      margin: 0 50% 0 0;
    }

    @include desktop {
      margin: 0 33% 0 0;
      width: 67%;
      padding: 20px 0 40px 0;
    }

    @include desktop-plus {
      padding: 50px 80px 50px 0;
    }
  }

  .map-wrapper {
    position: relative;

    @include tablet {
      margin: -5px 0 0 0;
      width: 50%;
      display: flex;
      order: 2;
      position: fixed;
      top: 0;
      right: 0;
    }

    @include desktop {
      width: 33%;
      max-width: $desktop;
    }
  }
}

.hundred-years-war {
  .mobile-header {
    @include tablet {
      display: none;
    }
  }

  .map-desktop-hint {
    @include tablet {
      @include pointer {
        width: 40%;
        margin: 0 0 0 60%;
      }
    }
  }
}
