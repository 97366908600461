.map-highlights {
  z-index: $z-index-highlights;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media print {
  footer {
    display: none;
  }

  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 10mm 0 0 0;
  }
}
