@import "../../ui/media.scss";

.references {
  font-size: 1.4rem;

  @include tablet {
    font-size: 1.5rem;
  }

  @include desktop {
    font-size: 1.6rem;
  }

  li {
    text-align: right;
    line-height: 1.4;
    margin: 0 0 14px 0;

    &:first-child {
      display: flex;
      justify-content: space-between;
    }
  }
}
