@import "../../ui/media.scss";
@import "../../ui/map.scss";
@import "../../components/Map/Base.scss";

.supernova-in-the-east {
  .map-desktop-hint {
    @include tablet {
      @include pointer {
        width: 40%;
      }
    }
  }

  .mobile-header {
    @include tablet {
      display: none;
    }
  }
}

.supernova-in-the-east-map {
  position: relative;
  overflow: hidden;

  @include tablet {
    position: fixed;
    top: 0;
    left: 0;
    max-height: 200vh;
    border-bottom: none;
    height: 58.88263665594856vw;
    width: 50vw;

    @include orientaion-landscape {
      overflow: visible;
      height: 100vh;
      width: 84.91467576791809vh;
    }
  }

  & > .map {
    position: relative;
    width: 100%;
    height: 0;
    padding: 0 0 117.7652733118971% 0;
    background-color: var(--color-white);

    & > .map-highlights,
    & > img,
    & > svg {
      transform-origin: top left;
    }

    & > .map-highlights {
      transition: transform 1ms ease 400ms;
    }

    & > img {
      z-index: 45;
      pointer-events: none;
      position: relative;
      opacity: 0.2;
      // transform: translate(0, 0) scale(1.05);
      transition: transform 800ms ease;
    }

    & > svg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: block;
      z-index: 5;
      opacity: 1;
      // transform: translate(0, 0) scale(1.05);
      transition: transform 800ms ease;
      stroke-width: 0.2;

      @include landscape {
        stroke-width: 0.4;
      }

      @include desktop {
        stroke-width: 0.5;
      }
    }
  }

  .year-1870 {
    & > .map-highlights {
      transform: translate(-120%, -30%);

      @include tablet {
        @include orientaion-landscape {
          transform: translate(-75vh, -24vh);
        }
      }
    }

    & > svg,
    & > img {
      transform: translate(-120%, -30%) scale(2.7);

      @include tablet {
        @include orientaion-landscape {
          transform: translate(-75vh, -24vh) scale(2);
        }
      }
    }
  }

  .year-1905 {
    & > .map-highlights {
      transform: translate(-70%, -18%);

      @include tablet {
        @include orientaion-landscape {
          transform: translate(-52vh, -15vh);
        }
      }
    }

    & > svg,
    & > img {
      transform: translate(-70%, -18%) scale(2.1);
      // transform: translate(0, 0) scale(1);

      @include tablet {
        @include orientaion-landscape {
          transform: translate(-52vh, -15vh) scale(1.8);
          // transform: translate(0, 0) scale(1);
        }
      }
    }
  }

  .year-1933 {
    & > .map-highlights {
      transform: translate(-40%, -10%);

      @include tablet {
        @include orientaion-landscape {
          transform: translate(-30vh, -5vh);
        }
      }
    }

    & > svg,
    & > img {
      transform: translate(-40%, -10%) scale(1.3);

      @include tablet {
        @include orientaion-landscape {
          transform: translate(-30vh, -5vh) scale(1.1);
        }
      }
    }
  }

  .year-1941 {
    & > .map-highlights {
      transform: translate(-15%, -15%);

      @include tablet {
        @include orientaion-landscape {
          transform: translate(-13vh, -12vh);
        }
      }
    }

    & > svg,
    & > img {
      transform: translate(-15%, -15%) scale(1.3);

      @include tablet {
        @include orientaion-landscape {
          transform: translate(-13vh, -12vh) scale(1.2);
        }
      }
    }
  }

  .year-1942 {
    & > .map-highlights {
      transform: translate(-15%, -15%);
      transform: translate(-1%, -5%);
      transform: translate(0, 0);

      @include tablet {
        @include orientaion-landscape {
          transform: translate(-1vh, -8vh);
          transform: translate(0, 0);
        }
      }
    }

    & > svg,
    & > img {
      transform: translate(0, 0) scale(1);

      @include tablet {
        @include orientaion-landscape {
          transform: translate(-1vh, -8vh) scale(1.1);
          transform: translate(0, 0) scale(1);
        }
      }
    }
  }

  .year-1942 {
    & > .map-highlights {
      transform: translate(0, 0);

      @include tablet {
        @include orientaion-landscape {
          transform: translate(-4%, -6%);
        }
      }
    }

    & > svg,
    & > img {
      transform: translate(0, 0) scale(1);

      @include tablet {
        @include orientaion-landscape {
          transform: translate(-4%, -6%) scale(1.1);
        }
      }
    }
  }

  .territory {
    transition: opacity 600ms ease;
    opacity: 1;
    fill: none;

    &.is-entering,
    &.is-leaving {
      opacity: 0;
    }
  }

  .timor {
    fill: #fffc99;
  }

  .us_philippines,
  .us_guinea,
  .us_salamons,
  .philippines {
    fill: rgb(166, 204, 245);
    // stroke: #dfb1df;
  }

  .korea {
    fill: #f3e3f3;
    stroke: #dfb1df;
  }

  .aus_borneo_west,
  .aus_borneo_east,
  .guinea {
    fill: #f3e8fa;
  }

  .malaya,
  .burma {
    fill: rgb(213, 250, 204);
    stroke: rgb(81, 160, 77);
  }

  .manchuria {
    fill: rgb(221, 244, 215);
    stroke: rgb(81, 160, 77);
    stroke-dasharray: 1 2;
  }

  .dutch {
    fill: rgb(255, 229, 206);
    stroke: rgb(179, 99, 29);
    stroke-dasharray: 1 2;
  }

  & > .has-item-hovered,
  & > .is-active {
    .territory {
      opacity: 0.3;

      &.is-active {
        opacity: 1;
      }
    }
  }

  .border {
    fill: var(--color-white);
    stroke: var(--color-white);
    stroke-width: 2px;
  }

  .sea {
    fill: #fff;
    stroke: #999;
    fill: url(#sea-bg);
  }

  #pattern_japan {
    rect {
      fill: white;
    }
    circle {
      fill: #bd0028;
    }
    path {
      fill: #bd0028;
    }
  }

  .japan {
    fill: url(#pattern_japan);
  }

  #pattern_japanese_expansion {
    rect {
      fill: white;
    }
    line {
      stroke: #ca2325;
      stroke-width: 2px;
    }
  }

  .mariana_jpn,
  .philippines_jpn,
  .solomon_jpn,
  .guinea_jpn,
  .borneo_jpn,
  .burma_jpn,
  .borneo,
  .beihai,
  .macao,
  .shantou,
  .fuzhou,
  .indochina,
  .beijing,
  .korea_exp,
  .shanghai,
  .manchukuo,
  .sakhalin,
  .liaodong,
  .taiwan {
    fill: url(#pattern_japanese_expansion);
    stroke: none;
    fill-opacity: 0.8;
  }

  .thailand {
    fill: url(#pattern_japanese_ally);
    stroke: none;
    fill-opacity: 0.8;
  }

  .sea-border {
    fill: none;
    stroke-dasharray: 3 2;
    stroke: #5487b1;
    transition: opacity 300ms ease;
  }
}

.supernova-in-the-east.is-loading {
  .sea-border {
    opacity: 0;
  }
}

.supernova-in-the-east .is-active {
  .sea-border {
    opacity: 0;
  }
}
