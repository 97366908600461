@import "../../ui/media.scss";

.map-legends {
  font-size: 1.4rem;
  display: flex;
  flex-wrap: wrap;
  padding: 14px 0 10px 0;

  @include tablet {
    padding: 10px 0 14px 0;
    font-size: 1.5rem;
  }

  @include desktop {
    padding: 20px 0 14px 0;
    font-size: 1.8rem;
  }

  li {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 0 0 6px 0;
    flex: 0 0 100%;
  }

  .tablet-and-up {
    display: none;

    @include tablet {
      display: flex;
    }
  }
}
