@import "../../ui/media.scss";
@import "../../ui/map.scss";
@import "../../components/Map/Base.scss";

.hundred-years-war-map {
  position: relative;
  height: 0;
  padding: 0 0 131.1594% 0;
  width: 100%;
  background: radial-gradient(closest-side at 65% 35%, #e1eeff 5%, #fff 180%);

  @include tablet {
    padding: 0 0 156.473829% 0;
  }

  .map-highlights {
    overflow: hidden;
  }

  #pattern_ally_burgundy,
  #pattern_burgundy {
    stroke: var(--color-flag-burgundy);
    stroke-width: 2px;
    fill: none;
  }

  #pattern_ally_burgundy {
    stroke: #5573af;
  }

  #pattern_uk_exp {
    stroke: #ca2325;
    stroke-width: 2px;
  }

  img {
    z-index: $z-index-image;
    pointer-events: none;
    position: relative;
    opacity: 0.2;
  }

  & > svg {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    display: block;
    z-index: $z-index-map;
    opacity: 1;
    border-bottom: 1px solid var(--color-text);

    @include tablet {
      border-bottom: none;
    }
  }

  .territory {
    transition: opacity 300ms ease;
    opacity: 1;
  }

  &.has-item-hovered,
  &.is-active {
    .territory {
      opacity: 0.4;

      &.is-active {
        opacity: 1;
      }
    }
  }

  .land {
    fill: white;
    stroke: none;
  }

  .mesh {
    fill: none;
    stroke: #777;
  }

  .uk_exp {
    fill: url(#pattern_uk_exp);
    stroke: none;
    fill-opacity: 0.8;
  }

  .france {
    fill: url(#pattern_france);
  }

  #pattern_france {
    rect {
      fill: #e1eafd;
    }

    path {
      fill: #94b4f2;
    }
  }

  .navarre {
    fill: none;
  }

  .avignon,
  .navarre {
    stroke: none;
  }

  .uk {
    fill: url(#pattern_uk);
  }

  #pattern_uk {
    rect {
      fill: white;
      fill: #fff6f6;
    }

    line {
      stroke: #ffc3c3;
    }
  }

  #pattern_scotland {
    rect {
      fill: rgb(196, 210, 254);
    }

    line {
      stroke: white;
    }
  }

  .scotland {
    fill: url(#pattern_scotland);
  }

  .avignon {
    fill: var(--color-flag-papacy);
  }

  .burgundy {
    fill: var(--color-flag-burgundy);
    fill: url(#pattern_burgundy);
  }

  .ally_burgundy {
    fill: url(#pattern_ally_burgundy);
  }
}

.hundred-years-war {
  &.is-loading {
    .territory {
      opacity: 0;
    }

    .hundred-years-war-map {
      pointer-events: none;
    }

    .map-highlights {
      opacity: 0;
    }
  }
}
