.checkbox-checked {
  .checkbox-checked-checkmark {
    stroke-width: 2px;
  }

  .checkbox-checked-box {
    stroke: var(--color-link);
  }
}

.radio-checked {
  .inner {
    stroke-width: 3px;
  }
}
