@import "../../ui/media.scss";
@import "../../ui/map.scss";

$button-height: 38px;
$button-height-tablet: 42px;

.map-info-toggler {
  position: absolute;
  bottom: 0;
  right: 16px;
  // transform: translateY(50%);
  transform: translateY(-14px);
  z-index: $z-index-toggle-button;

  .icon.close {
    // stroke: var(--color-white);
    // stroke-width: 2px;
    width: 14px;
    height: 14px;
  }

  .place {
    fill: var(--color-white);
  }

  @include pointer {
    display: none;
  }

  @include landscape {
    right: 25px;
  }
}
