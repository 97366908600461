@import "./ui/media.scss";
$z-index-backdrop: 400;
$z-index-overlay: 410;

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-text: #2e2d2e;
  --color-text-dark: #b0b5bb;
  --color-header-dark: #e8e9ea;
  --color-sub-text: #72757b;
  --color-footer-sub-text: #6e7177;
  --color-disabled: #e6e7e9;
  --color-grey-background-border: #eaeaea;
  --color-grey-background: #f7f7f7;
  --color-box-background: #fafafa;
  --color-link: #5c8df5;
  --color-link: #4170d3;
  --color-link-hovered: #459fff;
  --color-info-button: #4974d0;
  --color-info-button: #385ba5;
  --color-link-shadow: #326aa7;
  --color-link-bg: #e1eafd;
  --color-flag-papacy: #b780b7;
  --color-flag-burgundy: #218f24;
  --color-flag-england: #cd1f2c;
  --color-flag-scotland: #1f65bf;
  --color-success: #19b783;
  --color-error: #fd647b;
  --color-text-error: #f73542;
  --z-overlay: 600;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

section {
  padding: 0 16px;

  @include landscape {
    padding: 0 25px;
  }
}

article {
  max-width: $desktop-plus;
  margin: 0 auto;
}

.center {
  text-align: center;
}

.relative {
  position: relative;
}

.landscape-and-up {
  display: none;

  @include landscape {
    display: block;
  }
}

.tablet-and-up {
  display: none;

  @include tablet {
    display: block;
  }
}

#backdrop {
  transition: opacity 300ms ease;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-backdrop;
  background-color: var(--color-black);
}

.show-backdrop {
  #backdrop {
    opacity: 0.6;
    pointer-events: all;
  }
}

.page-breadcrumbs {
  margin: 0 0 40px 0;

  & > span {
    margin: 0 10px;
  }
}

hr {
  display: block;
  border: none;
  border-top: 1px solid var(--color-grey-background-border);
  height: 0;
  margin: 40px 0;
}

.hide {
  display: none;
}

.searchable-image {
  position: absolute;
  top: 0;
  left: 110vw;
  width: 500px;
  height: 500px;
  pointer-events: none;
}
