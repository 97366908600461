.logo {
  .cube-bg {
    fill: var(--color-white);
  }

  .logo-border {
    stroke: var(--color-text);
    fill: none;
  }

  .cube-left {
    stroke: none;
    fill: #f0f0f1;
  }

  .cube-right {
    stroke: none;
    fill: #f7f7f7;
  }
}
