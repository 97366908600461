@import "../../ui/media.scss";

.icon-info-wrapper {
  background-color: var(--color-grey-background);
  border-radius: 10px;
  font-size: 1.4rem;

  @include tablet {
    font-size: 1.6rem;
  }

  .icon-info {
    margin: 10px 0;
    padding: 6px 10px 10px 10px;

    @include tablet {
      padding: 10px 16px 16px 16px;
    }
  }

  ul {
    margin: 0 0 10px 0;
  }

  .icon {
    width: 14px;
    height: 14px;
    fill: var(--color-white);
    stroke: none;
  }

  .crown {
    stroke: var(--color-white);
    stroke-width: 4px;
  }

  .external-link {
    fill: none;
    stroke: var(--color-link);
    stroke-width: 2px;
    margin: 0 8px 0 4px;
    width: 20px;
    height: 20px;
  }

  li {
    display: flex;
    align-items: center;
    line-height: 1.4;
    margin: 0 0 5px 0;

    & > span {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: var(--color-white);
      background-color: var(--color-link);
      padding: 0 1px 0 0;
      padding: 2px;
      font-size: 1.4rem;
      margin: 0 8px 0 0;
    }
  }

  .icon-info-button {
    display: flex;
    justify-content: flex-end;
  }
}
