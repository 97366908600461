@import "../../ui/media.scss";

.footer-wrapper {
  .reddit-icon,
  .discord-icon,
  .twitter-icon {
    filter: grayscale(100%);
    opacity: 0.7;

    @include pointer {
      transition: filter 300ms ease, opacity 300ms ease;

      &:hover,
      &:focus {
        filter: grayscale(0%);
        opacity: 1;
      }
    }
  }

  .social .reddit-link {
    display: none;
  }

  .twitter-icon {
    position: relative;
    top: 3px;
  }

  .social {
    ul {
      margin: 4px 0 0 0;
    }

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0 6px 0 0;

      @include tablet {
        margin: 0 10px 0 0;
      }
    }

    .discord-icon {
      width: 26px;
      height: 26px;

      @include tablet {
        width: 34px;
        height: 34px;
      }
    }

    a {
      display: inline-block;
      width: 22px;
      height: 22px;
      line-height: 22px;
      padding: 2px;

      @include tablet {
        width: 30px;
        height: 30px;
        line-height: 30px;
      }
    }
  }
}

.map-on-left {
  .social {
    li {
      margin: 0 0 0 10px;

      @include tablet {
        margin: 0 0 0 14px;
      }
    }
  }
}

footer {
  min-height: 300px;
  position: relative;
  z-index: 200;
  background-color: var(--color-grey-background);
  border-top: 1px solid var(--color-grey-background-border);
  pointer-events: none;
  font-size: 1.4rem;

  @include tablet {
    font-size: 1.6rem;
  }

  strong {
    color: var(--color-sub-text);
  }

  article {
    padding: 40px 0;
  }

  .footer-wrapper {
    a {
      font-weight: normal;
      color: var(--color-text);
      pointer-events: initial;
    }
  }

  p.consent {
    color: var(--color-sub-text);
    font-size: 1.3rem;
    line-height: 1.4;
    margin: 80px 0 0 0;

    @include tablet {
      font-size: 1.4rem;
    }
  }

  .footer-logo {
    padding: 40px 0 0 0;

    @include desktop {
      padding: 0;
    }

    svg {
      @include desktop {
        margin: 0 14px 0 0;
      }
    }

    a {
      color: var(--color-footer-sub-text);
    }
  }

  &.map-on-right {
    @include tablet {
      border-top: none;
      background: linear-gradient(
        to right,
        var(--color-grey-background),
        rgba(255, 255, 255, 0) 50%
      );
    }

    @include desktop {
      background: linear-gradient(
        to right,
        var(--color-grey-background) 40%,
        rgba(255, 255, 255, 0) 67%
      );
    }

    .footer-border {
      @include tablet {
        display: block;
        height: 1px;
        background: linear-gradient(
          to right,
          var(--color-grey-background-border),
          transparent 50%
        );
      }

      @include desktop {
        background: linear-gradient(
          to right,
          var(--color-grey-background-border),
          transparent 67%
        );
      }
    }
  }

  .grid {
    @include tablet {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
      justify-items: stretch;
    }

    @include desktop-mid {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }

  &.map-on-right,
  &.map-on-left {
    .grid {
      @include tablet {
        display: block;
      }

      @include desktop {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }

  @include tablet {
    .maps {
      grid-column: 1 / 3;
    }

    .pages {
      grid-column: 3 / 5;
    }

    .support {
      grid-column: 1 / 3;
    }

    .social {
      grid-column: 3 / 5;
    }

    .contact {
      grid-column: 1 / 3;
    }

    p.consent {
      grid-column: 3 / 5;
      margin: 0 0 30px 0;
      order: 6;
    }

    .footer-logo {
      grid-column: 1 / 5;
      order: 7;
    }
  }

  @include desktop {
    .maps {
      grid-column: 1 / 2;
    }

    .pages {
      grid-column: 2 / 3;
    }

    .support {
      grid-column: 3 / 4;
    }

    .social {
      grid-column: 4 / 5;
    }

    .contact {
      grid-column: 1 / 3;
    }

    p.consent {
      grid-column: 2 / 5;
      order: 7;
      margin: 40px 0 0 0;
    }

    .footer-logo {
      grid-column: 1 / 5;
      order: 6;
    }
  }

  @include desktop-mid {
    .contact {
      grid-column: 5 / 6;
    }

    p.consent {
      grid-column: 2 / 5;
      margin: 50px 0 0 0;
    }

    .footer-logo {
      grid-column: 1 / 6;
    }
  }

  &.map-on-left {
    p.consent {
      display: none;
    }

    @include desktop {
      .maps {
        grid-column: 1 / 3;
      }

      .pages {
        grid-column: 3 / 5;
      }

      .support {
        grid-column: 1 / 3;
      }

      .social {
        grid-column: 3 / 5;
      }

      .contact {
        grid-column: 3 / 5;
      }

      .footer-logo {
        grid-column: 1 / 5;
      }

      @include orientaion-landscape {
        .maps {
          grid-column: 3 / 4;
        }

        .pages {
          grid-column: 4 / 5;
        }

        .support {
          grid-column: 3 / 4;
        }

        .social {
          grid-column: 4 / 5;
        }

        .contact {
          grid-column: 3 / 5;
        }

        .footer-logo {
          grid-column: 1 / 5;
        }
      }
    }
  }

  &.map-on-right {
    p.consent {
      display: none;
    }

    @include desktop {
      .maps {
        grid-column: 1 / 3;
      }

      .pages {
        grid-column: 3 / 5;
      }

      .support {
        grid-column: 1 / 3;
      }

      .social {
        grid-column: 3 / 5;
      }

      .contact {
        grid-column: 1 / 3;
      }

      .footer-logo {
        grid-column: 1 / 5;
      }

      @include orientaion-landscape {
        .maps {
          grid-column: 1 / 2;
        }

        .pages {
          grid-column: 2 / 3;
        }

        .support {
          grid-column: 1 / 2;
        }

        .social {
          grid-column: 2 / 3;
        }

        .contact {
          grid-column: 1 / 3;
        }

        .footer-logo {
          grid-column: 1 / 5;
        }
      }
    }
  }

  &.map-on-left {
    @include tablet {
      text-align: right;
      border-top: none;
      background: linear-gradient(
        to left,
        var(--color-grey-background),
        rgba(255, 255, 255, 0) 50%
      );
    }

    @include desktop {
      background: linear-gradient(
        to left,
        var(--color-grey-background),
        rgba(255, 255, 255, 0) 67%
      );
    }

    section {
      @include desktop-plus {
        padding: 0 25px 0 0;
        max-width: 1024px;
        margin: 0 0 0 70vh;
      }
    }

    article {
      @include desktop-plus {
        margin: 0;
      }
    }

    .footer-border {
      @include tablet {
        display: block;
        height: 1px;
        background: linear-gradient(
          to left,
          var(--color-grey-background-border),
          transparent 50%
        );
      }

      @include desktop {
        background: linear-gradient(
          to left,
          var(--color-grey-background-border),
          transparent 67%
        );
      }
    }
  }

  .social,
  .support,
  .contact,
  .maps,
  .pages {
    margin: 0 0 30px 0;

    @include tablet {
      margin: 0 0 50px 0;
    }

    li {
      height: 24px;

      @include landscape {
        height: 30px;
      }
    }

    strong {
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
    }

    a {
      white-space: nowrap;
      display: block;
      height: 24px;

      @include landscape {
        height: 30px;
      }
    }
  }

  .footer-logo {
    text-align: center;
    // margin: 60px 0 0 0;

    & > a {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
    }

    @include tablet {
      margin: 0;
    }

    svg {
      width: 40px;
      display: inline-block;
    }
  }

  &.map-on-left {
    @include tablet {
      .footer-logo {
        padding: 0;
        text-align: right;

        & > a {
          flex-direction: row;
        }

        svg {
          margin: 0 12px 0 0;
        }
      }
    }
  }

  &.map-on-right {
    @include tablet {
      .footer-logo {
        padding: 0;
        text-align: left;

        & > a {
          flex-direction: row;
        }

        svg {
          margin: 0 12px 0 0;
        }
      }
    }
  }
}
