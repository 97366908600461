@import "../../ui/media.scss";

.english-reigns,
.french-reigns {
  font-size: 1.2rem;
  width: 100%;
  position: relative;

  @include landscape {
    width: 400px;
  }

  .reigns-names {
    padding: 0 14px 16px 14px;
    position: relative;
    z-index: 2;
  }

  svg {
    overflow: visible;
  }

  text {
    text-anchor: middle;
  }

  a {
    text {
      fill: var(--color-info-button);
      opacity: 0.9;
      transition: opacity 300ms ease;

      &:hover,
      &:focus,
      &:active {
        opacity: 1;
      }
    }
  }

  tspan {
    font-weight: normal;
    font-size: 1.1rem;
    fill: var(--color-text);
  }

  .dotted {
    stroke-dasharray: 1 3;
    stroke: var(--color-sub-text);
    stroke-opacity: 0.3;
  }

  path {
    stroke: var(--color-sub-text);
    fill: none;
  }

  .reigns-link-bg {
    fill: var(--color-link-bg);
  }

  .reign-time-legend,
  .reign-time-label {
    fill: var(--color-sub-text);
    font-size: 0.9rem;
    text-anchor: end;
  }

  .reign-time-legend {
    text-anchor: start;
  }

  circle {
    fill: var(--color-sub-text);
  }

  .burgundians-bg {
    fill: var(--color-header-dark);
    // fill-opacity: 0.5;
    fill: #ecf1f7;
    fill: #f5f9ff;
  }

  .burgundians-text {
    // fill: var(--color-sub-text);
    font-size: 1rem;
  }
}
