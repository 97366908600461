.quiz-question {
  margin: 0 0 60px 0;
  position: relative;

  &:last-child {
    margin: 0 0 20px 0;
  }

  .input-header {
    padding: 0 0 10px 59px;
    margin: 0;
    min-height: 44px;
    color: #444;
  }

  .level-tag {
    position: absolute;
    text-align: center;
    top: 3px;
    line-height: 1.2;
    left: 3px;
  }
}
