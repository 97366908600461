@import "../../../ui/media.scss";

.icon-link {
  .map-link {
    cursor: pointer;
  }
}

.map-link {
  display: inline-flex;
  align-items: center;
  cursor: default;

  & > span {
    text-decoration: underline;
  }

  .arrow {
    font-style: normal;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    position: relative;
    top: 1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    background-color: var(--color-link);
    margin: 0 2px 0 5px;
    padding: 0 1px 0 0;
    font-size: 1.4rem;
  }
}

.map-legends {
  .map-link {
    & > span {
      text-decoration: none;
    }

    & > .arrow {
      display: none;
    }

    @include pointer {
      & > span {
        text-decoration: underline;
      }

      & > .arrow {
        display: inline-flex;
      }
    }
  }
}
