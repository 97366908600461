@import "../../ui/media.scss";

.year-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .action {
    flex: 0 0 auto;
  }

  .year-slider-inner {
    flex: 1 0 auto;
    padding: 0 10px;
  }

  .on-decrement {
    .button-surface {
      padding: 6px 7px 6px 5px;
    }
  }

  .on-increment {
    .button-surface {
      padding: 6px 5px 6px 7px;
    }
  }

  svg {
    fill: var(--color-white);
  }
}

.year-slider-indicator {
  display: flex;
  justify-content: center;
  pointer-events: none;
  margin: -5px 0 0 0;

  li {
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: var(--color-sub-text);
    opacity: 0.4;
    margin: 0 5px;
    transition: opacity 300ms ease;

    &.active {
      opacity: 0.8;
    }
  }
}

.year-slider-header {
  line-height: 1;
  text-align: center;
  transition: opacity 300ms ease;
  margin: 10px 0 -10px 0;

  @include tablet {
    margin: 0 0 -4px 0;
    font-size: 2rem;
  }
}

.the-punic-wars,
.supernova-in-the-east,
.hundred-years-war {
  &.is-loading {
    .year-slider-header {
      opacity: 0;
    }
  }
}
