@import "../ui/media.scss";

.app .home {
  h2 {
    font-size: 2.2rem;
    margin: 0 0 14px 0;

    @include tablet {
      font-size: 2.8rem;
    }
  }
}

.home article {
  margin: 0 auto 100px auto;
  max-width: 420px;
  position: relative;

  @include landscape {
    margin: 0 auto 110px auto;
  }

  @include desktop {
    max-width: 1000px;
    width: 100%;
  }

  &:first-child {
    margin: 0 auto 50px auto;

    @include landscape {
      margin: 0 auto 80px auto;
    }
  }
}

.home {
  padding: 70px 0 50px 0;

  .home-grid {
    @include desktop {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      justify-items: stretch;
      max-width: $desktop;
      column-gap: 200px;
      margin: 0 auto;
    }
  }

  .headers-wrapper {
    @include desktop {
      grid-column: 1/3;
      grid-row: 1/2;
    }
  }

  .maps-wrapper {
    @include desktop {
      grid-column: 1/2;
      grid-row: 2/5;
    }
  }

  .quiz-wrapper {
    @include desktop {
      grid-column: 2/3;
      grid-row: 2/3;
    }
  }

  .support-wrapper {
    @include desktop {
      grid-column: 2/3;
      grid-row: 3/4;
    }
  }

  .queue-wrapper {
    @include desktop {
      grid-column: 2/3;
      grid-row: 4/5;
    }
  }

  @include desktop-plus {
    padding: 100px 0;
  }

  .logo {
    display: none;
    position: relative;

    @include landscape {
      display: block;
      margin: 0 10px 0 0;
      width: 40px;
      top: 3px;
    }
  }

  h1 {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .support-wrapper {
    & > h2 > span {
      font-size: 1.4rem;
      margin: 0 0 0 3px;

      @include tablet {
        font-size: 1.8rem;
        margin: 0 0 0 6px;
      }
    }
  }

  .quiz-wrapper {
    line-height: 1.5;

    .quiz-wrapper-top {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    .as-button {
      margin: 14px 0 0 0;
    }

    .tag-new {
      background-color: var(--color-error);
      color: var(--color-white);
      padding: 3px 6px;
      line-height: 1;
      font-weight: 600;
      border-radius: 4px;
      box-shadow: 0 4px 16px -7px #711414;
    }
  }

  .banner {
    overflow: hidden;
    border-radius: 5px;
  }

  .banner-punic-wars,
  .banner-supernova-in-the-east,
  .banner-hundred-years-war {
    height: 0;
    padding: 0 0 40% 0;
    position: relative;
    box-shadow: 0 3px 15px -5px #000;

    a {
      color: var(--color-white);

      &:hover,
      &:active,
      &:focus {
        color: var(--color-white);
      }
    }
  }

  .banner {
    margin: 0 0 20px 0;

    @include tablet {
      margin: 0 0 30px 0;
    }

    @include desktop-plus {
      margin: 0 0 40px 0;
    }

    &:last-child {
      margin: 0 0 10px 0;
    }

    // &:first-child {
    //   margin: 60px 0 0 0;

    //   @include desktop-plus {
    //     margin: 80px 0 0 0;
    //   }
    // }
  }

  .banner-supernova-in-the-east {
    .banner-overlay {
      background: radial-gradient(
        rgba(0, 0, 0, 0.3) 50%,
        rgba(0, 0, 0, 0.6) 90%
      );
    }
  }

  .banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding: 10px;
    background: radial-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 90%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
      padding: 16px;
    }

    h3 {
      font-size: 2rem;
      font-weight: 500;
      letter-spacing: 0.01em;
      text-shadow: var(--color-black) 1px 0 10px;

      @include tablet {
        font-size: 2.8rem;
      }
    }

    & > div {
      text-align: right;
    }
  }

  .fake-ghost-button {
    height: 30px;
    line-height: 26px;
    display: inline-block;
    border: 2px solid var(--color-white);
    padding: 0 16px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);

    @include tablet {
      height: 42px;
      line-height: 38px;
    }
  }

  .progress-top {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    & > h2 {
      flex: 1 0 auto;
    }

    & > span {
      flex: 0 0 auto;
    }
  }

  .queue-wrapper {
    position: relative;

    p {
      line-height: 1.5;
      font-size: 1.6rem;
    }

    .updated {
      font-size: 12px;
      color: var(--color-sub-text);
      font-style: italic;

      @include landscape {
        font-size: 14px;
      }
    }

    ul {
      border-radius: 5px;
    }

    .queue-header {
      display: flex;
      justify-content: space-between;
      align-content: center;
      color: var(--color-sub-text);
      font-size: 12px;
      margin: 30px 0 0 0;

      @include tablet {
        font-size: 14px;
      }
    }

    .queue-header-name {
      flex: 1 0 auto;
    }

    .queue-header-chance {
      flex: 0 1 60%;
      padding: 0 0 0 40px;
    }

    li {
      padding: 4px 0;
      display: flex;
      justify-content: space-between;
    }

    .relative {
      margin: 4px 0 0 0;
    }
  }

  .queue-item-bg {
    background-color: var(--color-grey-background);
    height: 4px;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
  }

  .queue-item-fg {
    background-color: var(--color-sub-text);
    position: absolute;
    height: 4px;
    top: 0;
    left: 0;
  }

  .queue-item-bg {
    background: linear-gradient(0.25turn, #ee6432 10%, #47c247 85%);
  }
  .queue-item-fg {
    background-color: var(--color-grey-background);
  }

  .queue-item-chance {
    flex: 0 1 50%;
    text-align: right;
    line-height: 1;
    font-size: 1.2rem;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 0 0 30px;

    @include landscape {
      font-size: 1.4rem;
      flex: 0 1 60%;
      padding: 0 0 0 40px;
    }
  }

  .queue-item-percentage {
    flex: 0 0 35px;
    font-weight: 600;

    @include landscape {
      flex: 0 0 45px;
    }
  }

  .queue-item-progress {
    position: relative;
    flex: 1 0 auto;
  }

  .faq {
    display: none;

    li {
      margin: 0 0 50px 0;
      line-height: 1.5;
    }

    b {
      font-weight: 600;
    }

    a {
      font-weight: 500;
    }

    p {
      border-top: 1px solid var(--color-text-dark);
      margin: 3px 0 0 0;
      padding: 8px 0 0 0;
      color: var(--color-footer-sub-text);
    }
  }

  .disclaimer {
    line-height: 1.5;
    color: var(--color-footer-sub-text);
    font-size: 1.3rem;
    margin: 10px auto 0 auto;
    padding: 0 10px;

    @include tablet {
      padding: 0 16px;
      font-size: 1.4rem;
    }

    @include desktop-plus {
      font-size: 1.5rem;
      margin: 30px auto 0 auto;
    }
  }

  .screen-mode-info {
    display: none;

    @include tablet {
      line-height: 1.5;
      display: block;
      padding: 60px 0 0 0;
      // max-width: 340px;

      @include orientaion-landscape {
        display: none;
      }
    }

    .screen-mode-icon {
      animation: rotate 2s infinite;
      animation-direction: reverse;
      animation-timing-function: ease-in-out;
      width: 36px;
      height: 36px;
      border-radius: 18px;
      padding: 7px;
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 0 8px;
      background-color: var(--color-grey-background);
    }
  }

  .share-icons {
    margin: 10px 0 0 0;

    svg {
      transition: fill 300ms ease;
      fill: var(--color-text);
    }
  }

  .share-icon-facebook,
  .share-icon-twitter {
    background-color: var(--color-grey-background);
    border: 1px solid var(--color-grey-background-border);
    padding: 7px;
    width: 38px;
    height: 38px;
    border-radius: 19px;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 0 0;
  }

  .share-icon-twitter {
    &:hover,
    &:focus,
    &:active {
      svg {
        fill: #1da1f2;
      }
    }
  }

  .share-icon-facebook {
    padding: 8px;

    &:hover,
    &:focus,
    &:active {
      svg {
        fill: #4267b2;
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(270deg);
  }
}
