@import "../../ui/media.scss";

.audio {
  iframe {
    display: block;
  }

  li {
    margin: 0 0 10px 0;

    @include tablet {
      margin: 0 0 20px 0;
    }
  }
}
