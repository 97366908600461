@import "../../ui/media.scss";

.overlay {
  transition: opacity 300ms ease 30ms;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: var(--z-overlay);
  display: block;
  margin: 0;
  padding: 16px;
  border: 0;
  background-color: transparent;

  @include landscape {
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .overlay-body {
    border-radius: 10px;
    box-shadow: 0 0 20px -6px var(--color-black);
    background-color: var(--color-white);
    overflow-y: scroll;
    border: 2px solid var(--color-white);
    position: relative;

    @include tablet {
      margin: 3vh 0 0 0;
    }
  }

  .overlay-close {
    position: absolute;
    z-index: 2;
    bottom: -20px;
    left: 50%;
    margin: 0 0 0 -19px;

    @include tablet {
      margin: 0 0 0 -21px;
    }

    @include pointer {
      margin: 0;
      bottom: auto;
      left: auto;
      top: calc(3vh - 21px);
      right: -20px;
    }

    .close {
      width: 14px;
      height: 14px;
    }
  }

  .reigns-image-attributes {
    display: flex;
    justify-content: space-between;
    padding: 4px 16px 0 16px;
    font-size: 1rem;
    color: var(--color-sub-text);
    line-height: 1.4;

    @include landscape {
      font-size: 1.2rem;
    }
  }
}
