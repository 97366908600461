@import "../../ui/media.scss";

.question-complete {
  & > span {
    font-size: 3rem;
    font-weight: 300;
    display: block;
    margin: 0 0 2px 0;
  }
}

.choice {
  margin: 20px 0 0 0;
}

.question-answer {
  padding: 0 10px 10px 14px;
  font-size: 1.6rem;

  &:nth-child(even) {
    background-color: var(--color-grey-background);
  }

  li {
    line-height: 1;
    margin: 0 0 4px 0;
  }

  .question-explanation {
    margin: 8px 0 0 0;
    line-height: 1.5;
  }

  .question-label {
    padding: 12px 0 0 0;
    line-height: 1.5;
  }

  &.is-correct {
    border-left: 3px solid var(--color-success);
  }

  &.is-incorrect {
    border-left: 3px solid var(--color-error);
  }

  .question-answer-cells {
    margin: 4px 0 0 0;
  }

  &.is-incorrect {
    .question-answer-cells {
      @include landscape {
        display: flex;
      }

      & > div {
        @include landscape {
          flex: 0 0 50%;
        }
      }
    }
  }
}
