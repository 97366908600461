.rulers {
  padding: 100px 0;
}

.app .ruler-wrapper {
  max-width: 600px;
  margin: 0 auto;

  h1,
  h3 {
    margin: 80px 0 34px 0;
  }

  li {
    margin: 0 0 34px 0;
  }
}
