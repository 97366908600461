@import "../../ui/media.scss";

.supernova-in-the-east {
  padding: 0 0 80px 0;
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  & > .map-wrapper {
    position: relative;
  }

  .map-info-toggler {
    @include tablet {
      right: auto;
      left: 25px;
    }
  }

  @include tablet {
    & > .map-wrapper {
      z-index: 6;
    }

    & > .info-wrapper {
      position: relative;
      z-index: 7;
      max-width: $desktop;
      margin: 0 0 0 50%;
      width: 50%;

      @include orientaion-landscape {
        margin: 0 0 0 70vh;
        width: auto;
      }
    }
  }

  .year-1942 {
    .map-tooltip-info {
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      font-size: 1.3rem;

      @include tablet {
        font-size: 1.5rem;
      }
    }
  }
}
