.body-link {
  .icon.external-link {
    display: inline-block;
    width: 16px;
    height: 16px;
    fill: white;
    stroke: var(--color-link);
    vertical-align: baseline;
    margin: 0 1px 0 2px;
    position: relative;
    top: 2px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  .body-link {
    .icon.external-link {
      margin: 0 2px 0 -1px;
    }
  }
}
