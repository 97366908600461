@import "../../../ui/media.scss";

.timeline-list-item {
  position: relative;
  height: 26px;
  vertical-align: top;

  .list-item-icon {
    border-radius: 12px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    background-color: var(--color-link);
    color: var(--color-white);
    text-align: center;
    margin: 1px 12px 1px 0;
    display: inline-block;
    vertical-align: top;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-size: 1.2rem;

    @include tablet {
      font-size: 1.3rem;
    }
  }

  .list-item-name {
    width: calc(100% - 36px);
    display: inline-block;
    height: 26px;
    line-height: 26px;
    vertical-align: top;
  }

  a {
    font-size: 1.5rem;
  }

  i {
    display: none;
  }

  .ellipsis {
    display: inline-block;
    max-width: 100%;

    @include pointer {
      max-width: calc(100% - 26px);
    }
  }

  &.is-map-ref {
    .ellipsis {
      @include pointer {
        text-decoration: underline;
      }
    }

    i {
      @include pointer {
        display: inline;
        position: relative;
        top: 2px;
        margin: 0 0 0 4px;
        vertical-align: top;
      }
    }
  }
}
