.hundred-years-war {
  .france,
  .papacy,
  .england-exp,
  .scotland,
  .ally-burgundy,
  .burgundy,
  .england {
    & > div {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background-size: 20px 20px;
      margin: 0 10px 0 0;
    }
  }

  .england {
    & > div {
      background: url("./England.svg");
    }
  }

  .scotland {
    & > div {
      background: url("./Scotland.svg");
    }
  }

  .england-exp {
    & > div {
      background: url("./EnglandExp.svg");
    }
  }

  .papacy {
    & > div {
      background-color: var(--color-flag-papacy);
      opacity: 0.6;
    }
  }

  .france {
    & > div {
      background: url("./France.svg");
    }
  }

  .burgundy {
    & > div {
      background: url("./Burgundy.svg");
    }
  }

  .ally-burgundy {
    & > div {
      background: url("./AllyBurgundy.svg");
    }
  }
}
