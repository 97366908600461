.the-punic-wars {
  .syracuse,
  .messana,
  .rome,
  .carthagian-rome,
  .carthage {
    & > div {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background-size: 20px 20px;
      margin: 0 10px 0 0;
    }
  }

  .route {
    & > div {
      width: 22px;
      height: 0;
      margin: 0 8px 0 0;
      border-top: 2px dashed #666;
    }
  }

  .syracuse {
    & > div {
      background-color: #749bec;
    }
  }

  .rome {
    & > div {
      background-color: #f8bebd;
    }
  }

  .messana {
    & > div {
      background-color: #d08fe4;
    }
  }

  .carthage {
    & > div {
      background-color: #b9e9ce;
    }
  }

  .carthagian-rome {
    & > div {
      background: url("./legends/CarthagianRome.svg");
    }
  }
}
