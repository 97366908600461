@import "../../ui/media.scss";
@import "../../ui/map.scss";
@import "../../components/Map/Base.scss";

.the-punic-wars-map {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 0;
  padding: 0 0 81.95918367346939% 0;

  @include tablet {
    @include orientaion-landscape {
      position: fixed;
      height: 100vh;
      width: 122.01195219123506vh;
      right: 0;
      top: 0;
      padding: 0;
    }
  }
}

.the-punic-wars {
  position: relative;
  overflow: hidden;
}

.the-punic-wars {
  .info-wrapper {
    position: relative;
    z-index: 2;

    @include orientaion-landscape {
      @include tablet {
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 0) 100%
        );

        width: 50%;
        min-height: 100vh;
      }

      @include desktop {
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 75%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      @include wide {
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 80%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }
}
