.slides {
  position: relative;
  transform: translateX(0);

  &.prev,
  &.next {
    transition: transform 750ms ease;

    .slides-current {
      transition: opacity 750ms ease;
    }
  }

  &.prev {
    transform: translateX(100%);

    .slides-current {
      opacity: 0;
    }
  }

  &.next {
    transform: translateX(-100%);

    .slides-current {
      opacity: 0;
    }
  }

  .slides-current {
    opacity: 1;
  }

  .slides-next,
  .slides-prev {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    opacity: 0;
    transition: opacity 750ms ease;
  }

  &.animation {
    .slides-next,
    .slides-prev {
      opacity: 1;
    }
  }

  .slides-next {
    left: 100%;
  }

  .slides-prev {
    left: -100%;
  }
}

.slides-wrapper {
  position: relative;
  width: 100%;

  .slides-on-next,
  .slides-on-prev {
    position: absolute;
    z-index: 10;
    top: 50%;
    margin: -21px 0 0 0;
  }

  .slides-on-next {
    right: 6px;
  }

  .slides-on-prev {
    left: 6px;
  }
}
