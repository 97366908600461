@import "../../../ui/media.scss";

.year-1942-timeline-wrapper {
  position: relative;

  .timeline-anchor {
    .anchor-path {
      stroke: var(--color-sub-text);
    }

    .anchor-inner {
      stroke: none;
      fill: var(--color-sub-text);
    }

    .text-anchor {
      fill: var(--color-box-background);
      stroke: var(--color-sub-text);
      // stroke: none;
      // fill: var(--color-sub-text);
    }

    .anchor-outer {
      fill: var(--color-box-background);
      stroke: var(--color-sub-text);
    }
  }

  svg {
    width: 90px;
    height: 330px;
    display: inline-block;
    vertical-align: top;

    rect {
      fill: url(#vertical-timeline);
    }

    // line {
    //   stroke: var(--color-text-dark);
    //   stroke-width: 1px;
    // }
  }

  .timeline-links {
    width: calc(100% - 90px);
    display: inline-block;
    vertical-align: top;
  }
}

.year-1942-timeline {
  margin: 0 0 20px 0;

  .timeline {
    .center {
      padding: 15px 0 22px 0;
      // font-weight: 600;
      // color: var(--color-footer-sub-text);
    }
  }

  .timeline-box {
    border: 1px solid var(--color-grey-background-border);
    border-radius: 10px;
    background-color: var(--color-box-background);
    padding: 8px 12px 16px 12px;
  }

  .on-decrement {
    .button-surface {
      padding: 6px 7px 6px 5px;
    }
  }

  .on-increment {
    .button-surface {
      padding: 6px 5px 6px 7px;
    }
  }

  .timeline-buttons {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    top: 10px;
    left: 0;
    padding: 0 4px;
  }

  .timeline-interval {
    display: flex;
    flex-wrap: wrap;

    i {
      margin: 0 7px 0 4px;
    }

    & > div {
      font-size: 1.5rem;
      line-height: 1.5;
      color: var(--color-sub-text);
      flex: 0 0 auto;
    }
  }

  .timeline-header {
    margin: 14px 0 20px 0;

    @include tablet {
      margin: 0 0 20px 0;
    }

    span {
      color: var(--color-text);
    }
  }

  .timeline-date {
    position: absolute;
    color: var(--color-sub-text);
    font-size: 1.4rem;
    left: 0;
    font-style: italic;
  }
}
