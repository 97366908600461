@import "../../ui/media.scss";

.about {
  padding: 60px 0;

  @include tablet {
    padding: 100px 0;
  }

  .quoute {
    margin: 0 0 60px 0;
  }

  p {
    max-width: $landscape;

    b {
      font-weight: 600;
    }
  }

  .image-wrapper {
    border: 10px solid var(--color-white);
    box-shadow: 0 4px 24px -15px rgba(0, 0, 0, 0.6);
    min-width: 200px;
    margin: 30px 0 50px 0;
    display: none;

    @media (min-width: 600px) {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
    }

    @include tablet {
      width: 300px;
      top: -20px;
    }

    @include desktop {
      top: -120px;
    }

    @include desktop-plus {
      width: 400px;
    }
  }

  .paragraphs-and-image {
    position: relative;

    p {
      @media (min-width: 600px) {
        width: 300px;
      }

      @include tablet {
        width: calc(100% - 370px);
      }
    }
  }

  .paragraphs {
    max-width: $landscape;
    border-top: 1px solid var(--color-grey-background-border);
    padding: 60px 0 0 0;
    margin: 70px 0 0 0;

    @media (min-width: 600px) {
      width: 300px;
    }

    @include tablet {
      width: calc(100% - 370px);
      padding: 120px 0;
      margin: 140px 0 0 0;
    }
  }

  .footnote {
    margin: 50px 0 0 0;
    font-size: 1.3rem;

    @include tablet {
      margin: 80px 0 0 0;
      font-size: 1.5rem;
    }

    li {
      padding: 0 0 6px 14px;
      position: relative;
      line-height: 1.5;
    }

    sup {
      position: absolute;
      left: 0;
    }
  }
}
