@import "../../ui/media.scss";

$height: 38px;
$tablet-height: 42px;

button {
  border: 0;
  padding: 0;
  outline: none;
  background-color: transparent;
  font-size: 1.6rem;
  color: var(--color-text);
  font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  @include tablet {
    font-size: 1.8rem;
  }
}

.button {
  font-size: initial;
  position: relative;
  transform-origin: center;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  perspective-origin: 1000px;

  &.block {
    width: 100%;
  }

  &.action {
    width: $height;
    height: $height;

    @include tablet {
      width: $tablet-height;
      height: $tablet-height;
    }

    .button-surface {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: inherit;
      height: inherit;
      border-radius: 50%;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &.empty {
    pointer-events: none;

    .button-surface {
      visibility: hidden;
    }
  }

  &.disabled {
    pointer-events: none;

    .button-surface {
      background-color: var(--color-disabled);
      box-shadow: none;
      color: var(--color-footer-sub-text);
    }

    &:hover,
    &:active,
    &:focus {
      .button-surface {
        background-color: var(--color-disabled);
        box-shadow: none;
        color: var(--color-footer-sub-text);
      }
    }
  }

  &:hover,
  &:active,
  &:focus {
    .button-surface {
      background-color: var(--color-link-hovered);
      box-shadow: 0 4px 16px -7px transparent;
    }
  }

  &.ghost {
    .button-surface {
      background-color: var(--color-white);
      border: 2px solid var(--color-link);
      color: var(--color-link);
      line-height: 36px;

      @include tablet {
        line-height: 40px;
      }
    }

    &:hover,
    &:active,
    &:focus {
      .button-surface {
        background-color: var(--color-white);
        color: var(--color-link);
      }
    }
  }
}

.app {
  .as-button {
    color: var(--color-white);
    display: inline-block;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;

    &:hover,
    &:active,
    &:focus {
      background-color: var(--color-link-hovered);
      box-shadow: 0 4px 16px -7px transparent;
      color: var(--color-white);
    }

    &.block {
      display: block;
      width: 100%;
    }
  }
}

.as-button,
.button-surface {
  height: $height;
  line-height: $height;
  background-color: var(--color-link);
  color: var(--color-white);
  box-shadow: 0 4px 16px -7px var(--color-link-shadow);
  color: var(--color-white);
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  padding: 0 16px;
  border-radius: 5px;
  text-align: center;
  font-weight: 700;
  border: 0;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  @include tablet {
    height: $tablet-height;
    line-height: $tablet-height;
  }
}
