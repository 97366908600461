@import "../../ui/media.scss";

.quiz {
  padding: 10px 0 0 0;

  @include desktop-plus {
    padding: 100px 0 200px 0;
  }

  &.page {
    min-height: 100vh;
  }

  .button + .button {
    margin: 10px 0 0 0;
  }

  .quiz-wrapper {
    max-width: 600px;
    margin: 0 auto;
  }

  .quiz-intro {
    .choice {
      margin: 60px 0;
    }
  }

  .choice-label {
    & > span {
      margin: 0 10px 0 0;
      font-size: 2.6rem;
      vertical-align: middle;
    }
  }

  .page-breadcrumbs {
    font-weight: 600;
    color: var(--color-sub-text);

    button {
      font-weight: 600;
      color: var(--color-link);
      cursor: pointer;
    }
  }
}
