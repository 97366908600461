@import "../../ui/media.scss";

.page.supernova-in-the-east {
  min-height: 100vh;

  .info-wrapper {
    border-top: 1px solid var(--color-text);

    @include tablet {
      border-top: none;
    }
  }

  .icon-info-wrapper,
  .map-legends,
  .grid-references,
  .grid-header h1,
  .grid-body,
  .grid-sounds {
    transition: opacity 300ms ease;
  }

  &.is-loading {
    .icon-info-wrapper,
    .map-legends,
    .grid-references,
    .grid-body,
    .grid-header,
    .grid-sounds {
      opacity: 0;
    }

    @include tablet {
      .grid-header {
        opacity: 1;
      }

      .map-name-year,
      .grid-header h1 {
        opacity: 0;
      }
    }
  }
}

.supernova-in-the-east-info {
  @include tablet {
    padding: 20px 25px 40px 0;
  }

  .year-slider {
    max-width: $landscape;
    margin: 0 auto;
  }

  .year-slider-header {
    margin: 10px 0 -10px 0;

    @include desktop-mid {
      margin: 0 0 -4px 0;
    }
  }

  .grid {
    display: grid;

    @include desktop-mid {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      justify-items: stretch;
      column-gap: 40px;
    }

    @include desktop-plus {
      column-gap: 80px;
    }
  }

  .grid-sounds,
  .grid-references,
  .grid-header {
    margin: 20px 0 0 0;
  }

  .grid-header {
    h1 {
      margin: 0;
    }
  }

  .grid-header {
    order: 2;

    @include tablet {
      order: 1;
    }

    @include desktop-mid {
      grid-column: 1 / 3;
    }
  }

  .grid-slider-input-and-legend {
    order: 1;

    @include tablet {
      order: 2;
      margin: 50px 0;
    }

    @include desktop-mid {
      order: 2;
      grid-column: 1 / 2;
    }
  }

  .grid-body {
    order: 3;

    @include desktop-mid {
      grid-column: 2 / 3;
      margin: 50px 0;
    }
  }

  .grid-sounds {
    order: 4;

    @include desktop-mid {
      order: 5;
      grid-column: 2 / 3;
    }
  }

  .grid-references {
    order: 5;

    @include desktop-mid {
      order: 4;
      grid-column: 1 / 2;
    }
  }

  .supernova-1942-body {
    margin: 24px 0 0 0;

    @include desktop {
      margin: 60px 0 0 0;
    }

    strong {
      margin: 0 0 0 42px;
    }

    ol {
      margin: 14px 0 40px 0;

      @include desktop {
        margin: 14px 0 60px 0;
      }
    }
  }
}
