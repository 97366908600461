@import "../../ui/media.scss";

.grid-header {
  transition: opacity 300ms ease;

  .map-name {
    font-weight: 600;
    margin: 0 0 4px 0;

    .map-name-year {
      margin: 0;
      font-weight: 600;
      transition: opacity 300ms ease;
    }

    & > span {
      margin: 0 10px;
    }

    @include tablet {
      display: block;
      text-align: left;
    }
  }
}
