.result-indicator {
  .result-indicator-empty {
    fill: var(--color-white);
  }

  .result-indicator-none {
    fill: var(--color-grey-background-border);
  }

  .result-indicator-all {
    fill: var(--color-success);
  }

  text {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-size: 2rem;
    text-anchor: middle;
  }

  .result-indicator-text-result {
    font-size: 5rem;
    font-weight: 700;
    letter-spacing: -0.4rem;
  }

  .result-indicator-text-total {
    fill: var(--color-footer-sub-text);
    font-weight: 300;

    tspan {
      font-style: italic;
      font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    }
  }
}
