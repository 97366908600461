@import "../../ui/media.scss";

.the-punic-wars {
  .info-wrapper {
    max-width: $desktop;
    padding: 0 0 80px 0;

    @include orientaion-landscape {
      @include wide {
        margin: 0 0 0 15%;
        padding: 60px 100px 120px 0;
      }
    }
  }

  .map-desktop-hint {
    @include tablet {
      @include pointer {
        width: 40%;
        margin: 0 0 0 60%;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: fit-content(100%);
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    justify-items: stretch;
  }

  .grid-sounds,
  .grid-references,
  .grid-header {
    margin: 20px 0 0 0;
  }

  .grid-header {
    h1 {
      margin: 0;
    }
  }

  .grid-header {
    order: 2;
    grid-column: 1 / 3;

    @include desktop {
      order: 1;
      grid-column: 1 / 2;
    }

    @include orientaion-landscape {
      @include tablet {
        order: 1;
        margin: 40px 0 0 0;
      }

      @include desktop {
        grid-column: 1 / 3;
      }
    }
  }
  .grid-slider-input-and-legend {
    order: 1;
    grid-column: 1 / 3;

    @include tablet {
      margin: 14px 0 0 0;
    }

    @include desktop {
      order: 2;
      grid-column: 2 / 3;
      margin: 20px 0 0 0;
    }

    @include orientaion-landscape {
      @include tablet {
        order: 2;
        margin: 50px 0;
      }

      @include desktop {
        grid-column: 1 / 3;
      }

      @include desktop-plus {
        order: 3;
        grid-column: 2 / 3;
        margin: 50px 0 50px 40px;
      }

      @include wide {
        margin: 50px 0 50px 30px;
      }
    }

    // @include tablet {
    //   order: 2;
    //   margin: 40px 0;
    // }

    // @include desktop {
    //   order: 3;
    //   padding: 0 0 0 40px;
    // }

    // @include desktop-mid {
    //   padding: 0 20px 0 60px;
    // }

    // @include desktop-plus {
    //   padding: 0 40px 0 60px;
    // }
  }

  .grid-body {
    order: 3;
    grid-column: 1 / 3;
    max-width: 600px;

    @include orientaion-landscape {
      @include desktop-plus {
        order: 2;
        grid-column: 1 / 2;
        margin: 50px 0;
      }

      @include wide {
        margin: 50px 30px 50px 0;
      }
    }

    // @include tablet {
    //   margin: 40px 0;
    // }

    // @include desktop {
    //   order: 2;
    //   max-width: 400px;
    // }

    // @include desktop-plus {
    //   max-width: 600px;
    // }
  }
  .grid-sounds {
    order: 4;
    grid-column: 1 / 3;

    @include tablet {
      grid-column: 1 / 2;
      margin: 80px 20px 0 0;
    }

    @include orientaion-landscape {
      @include tablet {
        grid-column: 1 / 3;
        margin: 80px 0 0 0;
      }

      @include desktop-plus {
        grid-column: 1 / 2;
        margin: 80px 0 0 0;
      }
    }
  }

  .grid-references {
    order: 5;
    grid-column: 1 / 3;

    @include tablet {
      grid-column: 2 / 3;
      margin: 80px 0 0 20px;
    }

    @include orientaion-landscape {
      @include tablet {
        grid-column: 1 / 3;
        margin: 20px 0;
      }

      @include desktop-plus {
        grid-column: 2 / 3;
        margin: 80px 0 0 40px;
      }
    }

    // @include desktop {
    //   padding: 0 0 0 40px;
    // }

    // @include desktop-mid {
    //   padding: 0 40px 0 60px;
    // }
  }

  .grid-header,
  .grid-slider-input-and-legend,
  .grid-body,
  .grid-sounds,
  .grid-references {
    @include orientaion-landscape {
      @include tablet {
        max-width: 460px;
      }
    }
  }

  .icon-info-wrapper,
  .map-legends,
  .grid-references,
  .grid-header h1,
  .grid-body,
  .grid-sounds {
    transition: opacity 300ms ease;
  }

  &.is-loading {
    .icon-info-wrapper,
    .map-legends,
    .grid-references,
    .grid-body,
    .grid-header,
    .grid-sounds {
      opacity: 0;
    }

    @include tablet {
      .grid-header {
        opacity: 1;
      }

      .map-name-year,
      .grid-header h1 {
        opacity: 0;
      }
    }
  }
}
