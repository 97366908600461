@import "../../../ui/media.scss";

$height: 38px;
$tablet-height: 42px;

.choice {
  input {
    height: 0;
    width: 0;
    position: absolute;
    opacity: 0;
  }

  .input-header {
    margin: 0 0 10px 0;
    font-size: 1.5rem;
    line-height: 1.4;
    // color: var(--color-sub-text);

    @include tablet {
      font-size: 1.7rem;
    }
  }

  label {
    display: block;
    position: relative;
    border: 1px solid #b9c0de;
    cursor: pointer;
    margin: -1px 0 0 0;
    z-index: 1;

    &:focus-within,
    &:focus,
    &:hover,
    &:active {
      border: 1px solid var(--color-link);
      z-index: 2;

      .choice-label {
        color: var(--color-info-button);
        color: var(--color-black);
      }

      .choice-icon {
        border-right: 1px solid var(--color-link);
      }
    }

    &.is-first {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &.is-last {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .checkbox-checked,
  .checkbox-unchecked,
  .radio-checked,
  .radio-unchecked {
    stroke: var(--color-link);
    fill: var(--color-white);
    position: absolute;
    width: 20px;
    height: 20px;
    top: 8px;
    left: 8px;
    transition: transform 300ms ease;
    z-index: 1;

    @include tablet {
      top: 11px;
      left: 11px;
    }
  }

  .radio-checked,
  .checkbox-checked {
    stroke: var(--color-white);
    fill: var(--color-link);
    z-index: 2;
  }

  .choice-wrapper {
    .checkbox-unchecked,
    .radio-unchecked {
      transform: scale(1);
    }

    .radio-checked,
    .checkbox-checked {
      transform: scale(0);
    }

    &.is-checked {
      .checkbox-unchecked,
      .radio-unchecked {
        transform: scale(0);
      }

      .radio-checked,
      .checkbox-checked {
        transform: scale(1);
      }
    }
  }

  .choice-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: $height;
    height: $height;
    border-right: 1px solid #b9c0de;

    @include tablet {
      width: $tablet-height;
      height: $tablet-height;
    }
  }

  .choice-label {
    line-height: $height;
    height: $height;
    padding: 0 10px 0 48px;

    @include tablet {
      line-height: $tablet-height;
      height: $tablet-height;
      padding: 0 16px 0 58px;
    }
  }
}
