@import "../../ui/media.scss";

.body {
  ol {
    margin: 14px 0 0 0;

    @include tablet {
      margin: 24px 0 0 0;
    }

    li {
      padding: 0 0 20px 42px;
      position: relative;
      line-height: 1.4;
    }

    .number {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #eaeaea;
      font-weight: 700;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      font-size: 1.3rem;

      @include tablet {
        font-size: 1.5rem;
      }
    }
  }
}
