@import "../../ui/media.scss";

.overlay {
  .battle-overlay {
    max-height: 80vh;
    max-width: $landscape;

    h5 {
      margin: 16px 0 0 0;
      line-height: 1.4;

      @include landscape {
        margin: 25px 0 0 0;
      }
    }
  }

  .battle-date {
    font-size: 1.4rem;
    display: block;
    color: var(--color-sub-text);
    line-height: 1.4;
  }

  .battle-body {
    overflow: visible;
    padding: 0 14px 40px 14px;

    &.battle-formations {
      padding: 0 14px 14px 14px;

      @include landscape {
        padding: 0 23px 14px 23px;
      }
    }

    @include landscape {
      padding: 0 23px 50px 23px;
    }
  }

  .battle-image-attributes {
    display: flex;
    justify-content: space-between;
    padding: 4px 14px 0 14px;
    font-size: 1rem;
    color: var(--color-sub-text);
    line-height: 1.4;

    @include landscape {
      font-size: 1.2rem;
      padding: 6px 23px 0 23px;
    }
  }
}
