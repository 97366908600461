.icon-link {
  display: inline-flex;
  align-items: center;
  color: var(--color-link);
  font-weight: 600;
  cursor: pointer;
  transition: color 300ms ease;

  &:focus,
  &:hover,
  &:active {
    color: var(--color-info-button);
  }

  & > .icon-wrapper {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    position: relative;
    top: 1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-link);
    padding: 3px;
    margin: 0 2px 0 5px;
    color: var(--color-white);
    font-size: 1.4rem;
    text-decoration: none;

    & > svg {
      width: 14px;
      height: 14px;
    }
  }
}
