@import "../../ui/media.scss";
@import "../../ui/map.scss";
@import "../../components/Map/Base.scss";

.the-punic-wars-map {
  .map-transform {
    //   height: 0;
    //   padding: 0 0 81.95918367346938% 0;

    //   @include tablet {
    //     @include orientaion-landscape {
    //       position: fixed;
    //       height: 100vh;
    //       width: 122.01195219123506vh;
    //       right: 0;
    //       top: 0;
    //       padding: 0;
    //     }
  }

  canvas {
    position: absolute;
    z-index: 2;
    // image-rendering: optimizeSpeed;
    // image-rendering: -moz-crisp-edges;
    // image-rendering: -webkit-crisp-edges;
    // image-rendering: pixelated;
    // image-rendering: crisp-edges;
    width: 100%;
    height: 100%;
  }

  // canvas {
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }

  // canvas {
  //   width: 100%;
  //   height: 0;
  //   display: block;
  //   padding: 0 0 52.80312907431551% 0;
  //   padding: 0 0 77.31691510045366% 0;
  // }

  img {
    position: absolute;
    z-index: 2;
    opacity: 0.3;
    // opacity: 0;
    z-index: 1;
  }
}
