@import "../../ui/media.scss";

.quiz-batch {
  .quiz-batch-top {
    display: flex;
    margin: 0 0 50px 0;
    justify-content: space-between;
    align-items: baseline;

    span {
      font-size: 2.5rem;
      font-weight: 300;
      display: inline-block;
      line-height: 1.3;

      @include tablet {
        font-size: 3rem;
      }

      &:last-child {
        font-size: inherit;
        flex: 0 0 60px;
        text-align: right;
      }
    }
  }

  .unanswered-error {
    opacity: 0;
    transition: 300ms ease opacity;
    color: var(--color-text-error);
    font-weight: 500;
    line-height: 1.4;
  }

  &.has-error {
    .unanswered-error {
      opacity: 1;
    }
  }
}
