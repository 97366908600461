@import "../../../ui/media.scss";

$height: 34px;
$half-height: $height / 2;

@mixin track {
  -webkit-appearance: none;
  background-color: red;
  height: $height;
  opacity: 0;
}

@mixin thumb {
  box-shadow: none;
  border: none;
  width: $height;
  height: $height;
  border-radius: $half-height;
  background-color: red;
  opacity: 0;
  position: relative;
  cursor: pointer;
}

.slider {
  display: block;
  position: relative;

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    .slider-handle-focus {
      opacity: 0.2;
      transform: scale(1);
    }
  }

  input {
    width: 100%;
    -webkit-appearance: none;
    background-color: transparent;
    cursor: pointer;
    margin: 0;
    display: block;
    outline: none;

    &::-webkit-slider-runnable-track {
      @include track;
    }
    &::-moz-range-track {
      @include track;
    }
    &::-ms-track {
      @include track;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      @include thumb;
      margin-top: 0;
    }

    &::-moz-range-thumb {
      @include thumb;
    }

    &::-ms-thumb {
      @include thumb;
    }
  }
}

.slider-track-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  padding: $half-height;
  pointer-events: none;

  .slider-track {
    margin: -1px 0 0 0;
    height: 2px;
    border-radius: 2px;
    background-color: var(--color-link);
    overflow: hidden;
  }

  .slider-handle {
    position: absolute;
    top: 0;
    left: 0;
    margin: -6px 0 0 -5px;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background-color: var(--color-link);
    transition: background-color 300ms ease;
  }

  .slider-track-inner {
    background-color: var(--color-disabled);
    height: 2px;
  }

  .slider-handle-focus {
    @include thumb;
    background-color: var(--color-link);
    margin: -12px 0 0 -12px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.2;
    z-index: 10;
    transform: scale(0);
    transition: transform 300ms ease;
  }
}
