.icon {
  fill: var(--color-text);
  stroke: none;
  display: block;
}

button,
.button,
a {
  .icon {
    fill: var(--color-white);
  }

  .close,
  .icon.external-link {
    fill: none;
    stroke: var(--color-white);
    stroke-width: 2px;
  }

  .crown {
    fill: none;

    line {
      stroke: var(--color-white);
      stroke-width: 4px;
    }

    path {
      stroke: none;
      fill: var(--color-white);
    }
  }
}
