@import "../../ui/media.scss";
@import "../../ui/map.scss";

.map-desktop-hint {
  display: none;

  @include pointer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding: 20% 0 0 0;
    z-index: $z-index-desktop-hint;
    transition: opacity 300ms ease;
    opacity: 1;

    & > div {
      padding: 10px 5px 10px 15px;
      border-radius: 35px;
      width: 70px;
      height: 70px;
      background-color: rgba(255, 255, 255, 0.8);
      animation: hint 2s infinite;
      animation-timing-function: ease-in-out;
      animation-direction: alternate;
    }

    svg {
      width: 50px;
      height: 50px;
      fill: var(--color-link);
      stroke: none;
    }
  }

  &.has-acknowledged {
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes hint {
  from {
    transform: translate3d(24px, 0, 0) rotate(20deg);
  }

  to {
    transform: translate3d(-24px, 0, 0) rotate(-20deg);
  }
}
