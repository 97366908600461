@import "../../ui/media.scss";

.pending {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 100px auto;
  height: 100px;

  span {
    display: inline-block;
    position: absolute;
    left: 15px;
    top: 16px;
    width: 8px;
    height: 8px;
    border-radius: 50%;

    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-delay: 200ms;
    animation-direction: alternate;
    transform: translateY(5px);
    animation-name: lazy;

    &:first-child {
      left: 2px;
      animation-delay: 100ms;
    }

    &:last-child {
      left: 28px;
      animation-delay: 300ms;
    }
  }
}

.lazy-error {
  max-width: $landscape;
  margin: 100px auto;
  text-align: center;
  padding: 0 16px;
  height: 100px;

  @include landscape {
    padding: 0 25px;
  }
}

@keyframes lazy {
  0% {
    transform: translateY(26px);
    opacity: 1;
    background-color: #d1f2eb;
  }
  100% {
    background-color: #aed6f1;
    transform: translateY(0px);
    opacity: 1;
  }
}
