@import "../../ui/media.scss";

.mobile-header {
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
  color: var(--color-header-dark);
  background-color: var(--color-black);

  @include landscape {
    padding: 4px 25px;
  }

  @include tablet {
    padding: 8px 25px;

    @include orientaion-landscape {
      display: none;
    }
  }

  .map-name {
    font-weight: 900;
  }

  span {
    font-size: 1.2rem;
    margin: 0 2px 0 0;
    color: var(--color-text-dark);
  }
}
