@import "../../ui/media.scss";

.not-found {
  padding: 60px 0;

  @include tablet {
    padding: 100px 0;
  }

  .quoute {
    margin: 0 0 60px 0;
  }

  p {
    max-width: $landscape;

    b {
      font-weight: 600;
    }
  }
}
