.iframe {
  width: 100%;
  position: relative;

  .iframe-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-grey-background);
    z-index: 5;
    pointer-events: all;
    transition: opacity 300ms ease;
  }

  &.loaded {
    .iframe-overlay {
      opacity: 0;
      pointer-events: none;
    }
  }

  .iframe-mock,
  iframe {
    width: 100%;
    height: 232px;
    border: 0;
    margin: 0;
    padding: 0;
  }
}
