@import "../../ui/media.scss";

.acknowledgements {
  padding: 60px 0;

  @include tablet {
    padding: 100px 0;
  }

  .quoute {
    margin: 0 0 60px 0;
  }

  ul {
    margin: 0 0 50px 0;
  }

  b {
    font-weight: 600;
  }

  .acknowledgements-tools {
    a {
      display: flex;
      align-items: center;
      color: var(--color-text);
      font-weight: normal;

      &:active,
      &:hover,
      &:focus {
        color: var(--color-text);
      }
    }

    .logo-wrapper {
      width: 22px;
      height: 22px;
      margin: 0 14px 0 0;
    }

    .cloudflare {
      padding: 6px 0 0 0;
    }
  }

  .editors {
    .logo-wrapper {
      padding: 1px;
    }
  }

  .flex {
    @include tablet {
      display: flex;
      justify-content: space-between;
    }
  }

  .acknowledgements-preinfo {
    max-width: $landscape;

    @include tablet {
      flex: 0 0 50%;
    }
  }

  .acknowledgements-tools {
    margin: 80px 0 0 0;
    @include landscape {
      display: flex;

      & > div {
        flex: 0 0 50%;
      }
    }

    @include tablet {
      margin: 0;
      display: block;
      padding: 10px 0 0 80px;
      flex: 0 0 50%;
    }

    @include desktop {
      display: flex;
    }
  }

  .sass {
    .logo-wrapper {
      position: relative;
      top: 3px;
    }
  }

  .babel {
    .logo-wrapper {
      padding: 0;
      position: relative;
      top: 7px;
    }
  }

  .footnote {
    margin: 50px 0 0 0;
    font-size: 1.3rem;

    @include tablet {
      margin: 80px 0 0 0;
      font-size: 1.5rem;
    }

    li {
      padding: 0 0 6px 14px;
      position: relative;
      line-height: 1.5;
    }

    sup {
      position: absolute;
      left: 0;
    }
  }
}
