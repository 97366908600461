@import "../../ui/media.scss";

.hundred-years-war {
  .grid {
    display: grid;
    grid-template-columns: fit-content(100%);

    @include desktop {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      justify-items: stretch;
    }
  }

  .grid-sounds,
  .grid-references,
  .grid-header {
    margin: 20px 0 0 0;
  }

  .grid-header {
    h1 {
      margin: 0;
    }
  }

  .grid-header {
    order: 2;

    @include tablet {
      order: 1;
    }

    @include desktop {
      grid-column: 1 / 3;
    }
  }
  .grid-slider-input-and-legend {
    order: 1;

    @include tablet {
      order: 2;
      margin: 40px 0;
    }

    @include desktop {
      order: 3;
      padding: 0 0 0 40px;
    }

    @include desktop-mid {
      padding: 0 20px 0 60px;
    }

    @include desktop-plus {
      padding: 0 40px 0 60px;
    }
  }
  .grid-body {
    order: 3;

    @include tablet {
      margin: 40px 0;
    }

    @include desktop {
      order: 2;
      max-width: 400px;
    }

    @include desktop-plus {
      max-width: 600px;
    }
  }
  .grid-sounds {
    order: 4;
  }
  .grid-references {
    order: 5;

    @include desktop {
      padding: 0 0 0 40px;
    }

    @include desktop-mid {
      padding: 0 40px 0 60px;
    }
  }

  .icon-info-wrapper,
  .map-legends,
  .grid-references,
  .grid-header h1,
  .grid-body,
  .grid-sounds {
    transition: opacity 300ms ease;
  }

  &.is-loading {
    .icon-info-wrapper,
    .map-legends,
    .grid-references,
    .grid-body,
    .grid-header,
    .grid-sounds {
      opacity: 0;
    }

    @include tablet {
      .grid-header {
        opacity: 1;
      }

      .map-name-year,
      .grid-header h1 {
        opacity: 0;
      }
    }
  }
}
