.supernova-in-the-east {
  .japan-ally,
  .japan-exp,
  .timor,
  .us,
  .dutch,
  .uk,
  .guinea,
  .manchuria,
  .korea,
  .japan {
    & > div {
      width: 39px;
      height: 26px;
      // background-size: 20px 20px;
      background-size: cover;
      margin: 0 10px 0 0;
    }
  }

  .japan {
    & > div {
      background-image: url("./Japan.svg");
    }
  }

  .manchuria {
    & > div {
      background-color: rgb(221, 244, 215);
      border: dashed 1px rgb(81, 160, 77);
    }
  }

  .korea {
    & > div {
      background-color: #f3e3f3;
      border: dashed 1px #b878b8;
    }
  }

  .us {
    & > div {
      background-color: rgb(166, 204, 245);
      border: dashed 1px #7792ce;
    }
  }

  .uk {
    & > div {
      background-color: rgb(213, 250, 204);
      border: dashed 1px rgb(81, 160, 77);
    }
  }

  .dutch {
    & > div {
      background-color: rgb(255, 229, 206);
      border: dashed 1px rgb(179, 99, 29);
    }
  }

  .timor {
    & > div {
      background-color: #fffc99;
      border: dashed 1px #a7a21f;
    }
  }

  .guinea {
    & > div {
      background-color: #f3e8fa;
      border: dashed 1px rgb(150, 97, 150);
    }
  }

  .japan-exp {
    & > div {
      background: url("./JapanExp.svg");
      background-size: cover;
    }
  }

  .japan-ally {
    & > div {
      background: url("./JapanAlly.svg");
      background-size: cover;
    }
  }
}
